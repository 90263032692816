@font-face {
   font-family: 'Material Icons';
   font-style: normal;
   font-weight: 400;
   src: local('Material Icons'),
        local('MaterialIcons-Regular'),
        /*url(static/MaterialIcons-Regular.ttf) format('truetype');*/
        url(http://cdn.bootcss.com/material-design-icons/4.0.0/font/MaterialIcons-Regular.ttf) format('truetype')
}
.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/*覆盖浏览器默认的margin*/
body{
  margin: 0;
}

/*将div的默认鼠标样式修改为箭头*/
div{
  cursor: default;
}

/************** 登录面板 ***************/
.loginpaneldiv {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.loginpanelback {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.loginpanel {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 380px;
  padding: 20px 40px 80px 40px;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  background: white;
  z-index: 999;
}
.loginmobilepanel {
  width: 100%;
  margin: auto 20px;
  padding: 50px 40px;
  border-radius: 6px;
  background: white;
  z-index: 999;
  position: relative;
}
.loginpanelclose {
  display: block;
  padding: 6px;
  color: #313138;
  cursor: pointer;
  border-radius: 5px;
  margin: 6px 0 6px auto;
  float: right;
}
.loginpanelclose:hover {
  background-color: #84858d14;
  color: #25262b;
}
.loginpanelbtn{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  background-color: #1a73e8;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  letter-spacing: 20px;
  text-indent: 20px;
}
.loginpanelactivate{
  display: block;
  margin-bottom: 10px;
  padding: 12px 8px;
  font-size: 14px;
  font-weight: 600;
  color: #1a73e8;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  letter-spacing: 20px;
  text-indent: 20px;
  cursor: pointer;
}
.loginpanelactivate:hover{
  background-color: rgba(26, 115, 232, 0.05);
}
.loginpanelops {
  display: flex;
  align-items: center;
  margin-top: 12px;
}


/************** 注册登录界面 ***************/
.loginback{
  display: block;
}
.loginmain{
  display: block;
  margin: 80px auto auto auto;
  width: 450px;
}
.loginmainmobile{
  display: block;
  margin: 60px auto 40px auto;
  width: 100%;
}
.logincontain{
  padding: 50px 40px;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
}
.logincontainmobile{
  padding: 0 20px;
}

.logintitle{
  display: block;
  text-align: center;
}
.logintitleimag{
  display: block;
  text-align: center;
  height: 20px;
  margin: 10px auto;
}
.loginlogin{
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 12px 0;
  font-size: 24px;
  font-weight: 500;
  color: #1a1a1a;
}
.logindes{
  display: block;
  text-align: center;
  padding: 8px 0;
  font-size: 15px;
  font-weight: 400;
  color: #1a1a1a;
}
.loginemialdiv{
  display: block;
}
.loginemailtitleinitial{
  visibility: hidden;
}
.loginemailtitleactivate{
  display: inline-block;
  position: relative;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 400;
  color: #1a73e8;
  top:10px;
  right: auto;
  bottom: auto;
  left: 20px;
  background-color: white;
}
.loginemailtitleerror{
  display: inline-block;
  position: relative;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 400;
  color: #cc0000;
  top:10px;
  right: auto;
  bottom: auto;
  left: 20px;
  background-color: white;
}
/* 初始化 */
.loginemailinitial{
  border-radius: 4px;
  border:1px solid #b4b4b4;
  height: 36px;
  width: calc(100% - 22px);
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
}
/* 激活 */
.loginemailactivate{
  border-radius: 4px;
  border:1px solid #1a73e8;
  height: 36px;
  width: calc(100% - 22px);
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
}
.loginemailactivate:focus{
  border: 1px solid rgba(255,255,255,0);
  outline: 2px solid #1a73e8;
}
/* 错误 */
.loginemailerror{
  border-radius: 4px;
  border:1px solid #cc0000;
  height: 36px;
  width: calc(100% - 22px);
  padding: 5px 10px;
  font-size: 16px;
}
.loginemailerror:focus{
  border: 1px solid rgba(255,255,255,0);
  outline: 2px solid #1a73e8;
}
.loginerror{
  display: inline-block;
  color: #d93025;
  font-size: 13px;
  line-height: normal;
}
.loginerrorhidden{
  visibility: hidden;
}
.loginerroricon{
  font-size: 15px;
  font-weight: bolder;
  padding: 4px 5px;
}
.loginforget{
  /*display: inline-block;*/
  padding: 20px 0;
  font-size: 14px;
  font-weight: 500;
  color: #1a73e8;
}
.loginremind{
  padding: 20px 0;
  font-size: 14px;
  font-weight: 500;
}
.loginops{
  position: relative;
  padding: 10px 0;
}
.logincreate{
  cursor: pointer;
  display: inline-block;
  padding: 12px 8px;
  font-size: 14px;
  font-weight: 600;
  color: #1a73e8;
  border-radius: 5px;
}
.logincreate:hover{
  background-color: rgba(26, 115, 232, 0.05);
}
.loginactivate{
  display: block;
  margin-top: 10px;
  padding: 12px 8px;
  font-size: 14px;
  font-weight: 600;
  color: #1a73e8;
  border-radius: 5px;
  width: fit-content;
}
.loginactivatehidden{
  visibility: hidden;
}
.loginactivate:hover{
  background-color: rgba(26, 115, 232, 0.05);
}
.loginnext{
  cursor: pointer;
  display: inline-block;
  float: right;
  border-radius: 4px;
  color: white;
  background-color: #1a73e8;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 18px;
}
.loginlan{
  cursor: pointer;
  display: inline-block;
  color: #202124;
  padding: 6px 0 6px 16px;
  line-height: 1;
  font-size: 13px;
  vertical-align: center;
}
.loginlandropdown{
  font-weight: bold;
  vertical-align: center;
  font-size: 13px;
  padding: 0 6px;
}
.loginlanmenu{
  display: inline-block;
  position: absolute;
  background-color: #f5f5f5;
  width: 140px;
  border:1px solid #e1e1e1;
  z-index: 1;
  top:0;
  bottom: auto;
  left: 100px;
  right: 30px;
  box-shadow: 0 1px 10px #cecece;
}
.loginlanmenuhidden{
  visibility: hidden;
  display: inline-block;
  position: absolute;
}
.loginlanitem{
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  padding: 12px;
  background-color: white;
}
.loginlanitem:hover{
  background-color: #e1e1e1;
}
.loginlanitem{

}
.loginothers{
  display: inline-block;
  float: right;
}
.loginother{
  display: inline-block;
  color: #757575;
  border-radius: 2px;
  padding: 6px 16px;
  font-weight: 300;
  font-size: 13px;
}
.loginuserdiv{
  display: flex;
  padding: 0 0 20px 0;
}
.loginuseravatar{
  display: inline-block;
  border-radius: 100%;
  height: 20px;
  padding: 0 5px;
}
.loginuseremail{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #c0c0c0;
  font-size: 13px;
  font-weight: 600;
  color: #3c4043;
  padding: 6px 10px;
  border-radius: 20px;
  text-align: center;
}
.loginuseremailicon{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: white;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.loginpasswddiv{
  display: block;
  padding: 12px 0 12px 0;
}
.loginpasswdche{
  display: inline-block;
  width: 15px;
  height: 15px;
}
.loginpasswddes{
  display: inline-block;
  padding-left: 10px;
  font-size: 15px;
  color: #1a1a1a;
}

.registernameinitial{
  border-radius: 4px;
  border:1px solid #b4b4b4;
  height: 36px;
  width: 94%;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
}
.registernameactivate{
  border-radius: 4px;
  border:1px solid #1a73e8;
  height: 36px;
  width: 94%;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
}
.registernameactivate:focus{
  border: 1px solid rgba(255,255,255,0);
  outline: 2px solid #1a73e8;
}
.registernameerror{
  border-radius: 4px;
  border:1px solid #cc0000;
  height: 36px;
  width: 94%;
  padding: 5px 10px;
}
.registernameerror:focus{
  border: 1px solid rgba(255,255,255,0);
  outline: 2px solid #1a73e8;
}
.registernametitleinitial{
  visibility: hidden;
}
.registernametitleactivate{
  display: inline-block;
  position: relative;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 400;
  color: #1a73e8;
  top:10px;
  right: auto;
  bottom: auto;
  left: 20px;
  background-color: white;
}
.registernametitleerror{
  display: inline-block;
  position: relative;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 400;
  color: #cc0000;
  top:10px;
  right: auto;
  bottom: auto;
  left: 20px;
  background-color: white;
}
.registertip{
  display: inline-block;
  color: #6e6e6e;
  font-size: 13px;
  line-height: normal;
}
.registertiphidden{
  display: none;
}
.registertipdiv{
  height: 26px;
}
.registererror{
  display: inline-block;
  color: #d93025;
  font-size: 13px;
  line-height: normal;
}
.registererrorhidden{
  display: none;
}

.forgettip{
  color: #202124;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 1.5;
  padding: 12px 0;
  text-align: center;

}
.forgetcodetip{
  padding-bottom: 10px;
  font-size: 15px;
  color: #202124;
}
.forgetopsdiv{
  padding-top: 32px;
}
.forgeterror{
  display: inline-block;
  color: #d93025;
  font-size: 13px;
  line-height: normal;
}
.forgeterrorhidden{
  visibility: hidden;
}
.resetpasswdtip{
  color: #202124;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 1.5;
  padding: 12px 0;
}

/*注册成功弹窗*/
.registersuccessdiv{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.registersuccess{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 380px;
  height: 350px;
  padding: 50px 40px;
  border: 1px solid #bbbbbb;
  border-radius: 6px;
  background: white;
  z-index: 9999;
}
.registersuccessicon{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 5px solid #EDF8E6;
  color: #A5DC86;
  font-size: 70px;
  padding: 10px;
}
.registersuccesstitle{
  font-size: 20px;
  font-weight: 600;
}
.registersuccesstxt{
  font-size: 13px;
}
.registersuccessbtn{
  cursor: pointer;
  border-radius: 4px;
  color: white;
  background-color: #1a73e8;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0 10px 10px;
  width: 100px;
  text-align: center;
  letter-spacing: 10px;
  text-indent: 10px;
  user-select: none;
}

